import React, { useEffect,useState } from 'react'
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Navtab from '../../components/navtab';
import "./orders.css"
import {fetchOrders } from '../../statemanagement/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/Loading';
import { Button, Collapse } from 'react-bootstrap';
import { domain } from '../../domain';

const Order = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {orders, loading_orders} = useSelector(state => state.OrdersReducer);
    function formatDate(date, locales, options) {
        return new Date(date).toLocaleString(locales, options);
    }
    const [open,setOpen] = useState('');

    const [cart,setCart] = useState([]);
    const [show2, setShow2] = useState(false);
    const [price,setPrice] = useState('');
    const [tableId,setTableId] = useState('');
    const handleClose2 = () => setShow2(false);

    const updateOrder = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('access-token')}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
        "is_delivered": true
        });

        const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch(`${domain}/api/admin/orders/${id}/`, requestOptions)
        .then((response) => response.text())
        .then((result) =>{
            window.location.reload();
        })
        .catch((error) => console.error(error));
    }

    useEffect(() => {
        if(localStorage.hasOwnProperty("access-token") === false ){
            navigate('/home');
          }
        else{
            function getOrders(){
                dispatch(fetchOrders(localStorage.getItem('access-token')))
            }
            getOrders();
            const OrderDetailsInterval = setInterval(getOrders, 20000)
            return () => clearInterval(OrderDetailsInterval)
        }
    },[]);
    return (
        <div>
            <NavBar/>
            <div className='Address'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                <Row className='m-0 my-5'>
                    <Col className="p-0  mx-0 blyat" lg={3}>
                        <Navtab/>
                    </Col>
                    <Col lg={9} className='bobber'>
                        <div id='order' >
                        {
                             loading_orders ? <Loading/>
                            :
                            <>
                            <div className='ms-3 pt-3 row'>
                                <h1 className='col-xl-7 col-5 heading'>
                                    Siparişler
                                </h1>                                
                            </div>
                            <div className='ps-3 pe-3 pt-3 row'>
                            <div className="table-responsive-xl table-responsive-neek">
                                <table className="table">
                                <thead className='table-dark  container-fluid'>
                                    <tr>
                                    <th scope="col">No</th>
                                    <th scope="col">Miktar</th>
                                    <th scope="col">Tarih</th>
                                    <th scope="col">Ürünler</th>
                                    <th scope="col">Teslim edildi mi ?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    loading_orders ? <></>
                                        :
                                    orders.filter((order)=> order.is_delivered === false).map((e)=>
                                        <tr>
                                            <th>{e.id}</th>
                                            <td>{e.total}{"₺"}</td>
                                            <td>{formatDate(e.date_ordered,"en-US", { dateStyle: "short",timeStyle: "medium"})}</td>
                                            <td><a onClick={()=>{
                                                setShow2(true);
                                                setCart(e.order_items);
                                                setPrice(e.total);
                                                setTableId(e.tableId)
                                            }} className='btn p-1 m-1 btn-outline-dark address-link'>Ürünleri göster</a></td>
                                            <td> 
                                                <svg  onClick={()=>{updateOrder(e.id)}} className='checkMark' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                < path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                                </table>
                            </div>
                            </div>
                            </>
                        }
                            
                        </div>
                    </Col>
                </Row>    
            </Tab.Container>
            </div>
            <Footer/>
            {/* Current Cart Modal */}
            <Modal
            scrollable
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
                <Modal.Title>Siparişin Ürünleri</Modal.Title>
            </Modal.Header>
            <Modal.Body >
            <div className="table-responsive-xl" style={{width:'100%', overflowX:'scroll'}}>
            <table className="card table cart-table mb-0" style={{display:'table',width:'100%'}}>                
                <tbody>
                    {cart.map((data,index) => {
                    const { id, name, price, quantity, additional_products,total} = data;
                    const basePrice = parseFloat(price) || 0;
                    const itemAddOnPrice = additional_products
                        ? additional_products.reduce((acc, addOn) => {
                            return acc + parseFloat(addOn.price || 0);
                        }, 0)
                        : 0;
                    const itemTotalPrice = (basePrice + itemAddOnPrice) * quantity;
                    return (
                        <>
                        <tr key={id} >
                            <td colSpan={3} style={additional_products.length > 0 ? {borderTopWidth:'0px',borderBottomWidth:"0px"} : {}} >
                            <div className="product-name">
                                <p>{name}</p>
                            </div>
                            </td>
                            <td  colSpan={3} style={additional_products.length > 0 ? {borderTopWidth:'0px',borderBottomWidth:"0px"} : {}} >
                            <div className="prdct-qty-container">
                                <input
                                type="text"
                                name="qty"
                                className="qty-input-box"
                                value={quantity}
                                disabled
                                />
                            </div>
                            </td>
                            <td colSpan={3} style={additional_products.length > 0 ? {borderTopWidth:'0px',borderBottomWidth:"0px"} : {}}>
                            {itemTotalPrice.toFixed(2)}₺
                            </td>
                            <td colSpan={3} style={additional_products.length > 0 ? {borderTopWidth:'0px',borderBottomWidth:"0px"} : {}} >
                                <Button aria-controls="example-collapse-text" aria-expanded={open === index}  className='btn btn-secondary' onClick={()=>{ open === index ? setOpen('') : setOpen(index) }} 
                                > {open === index ? "▴" : "▾"} </Button>
                            </td>
                        </tr>
                        {additional_products && additional_products.length > 0 && (
                        <Collapse in={open === index} className='w-100'>
                                <div>
                                    <td colSpan={12} style={{borderBottomWidth:"0px",borderTopWidth:'0px'}} className='pt-0'>
                                        <ul className="list-unstyled ms-0 mb-0 pt-0">
                                        {additional_products.map((addOn, index) => (
                                            <li key={index}>
                                                <svg className='star ps-1 pe-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/></svg>
                                            {addOn.name} ({addOn.price}₺)
                                            </li>
                                        ))}
                                        </ul>
                                    </td>
                                </div>
                        </Collapse>
                        )}
                        </>
                    );
                    })}
                    <tr>

                    <td colSpan={6} className="text-left w-100">
                        Toplam<span className="ml-1 mr-2">:</span>
                        <span className="text-danger">
                        {price}₺ 
                        </span>
                        <div>
                        </div>
                    </td>
                    <td  colSpan={6}>
                    {`Masa: ${tableId}`}
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </Modal.Body>

            <Modal.Footer>
                    <button onClick={handleClose2} className="btn me-2b btn-danger">Kapat
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Order;