export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const DECREASE_QNTY = 'DECREASE_QNTY';
export const EMPTY_CART = 'EMPTY_CART';
export const GEL_AL = 'GEL_AL';
export const USE_POINTS = 'USE_POINTS';
export const GET_CART_IDS = 'GET_CART_IDS';
export const GET_TOTAL = 'GET_TOTAL';
export const REMOVE_ADDON_FROM_CART = 'REMOVE_ADDON_FROM_CART';

// Action: remove a single add-on from a cart item
export function RemoveAddOnFromCart(cartItemId, addOnName) {
  return {
    type: REMOVE_ADDON_FROM_CART,
    payload: { cartItemId, addOnName },
  };
}

// Toggle pickup/delivery
export const setGel_al = (item) => (dispatch) => {
  dispatch({
    type: GEL_AL,
    payload: item,
  });
};

// Toggle using points
export const setUsepoints = (item) => (dispatch) => {
  dispatch({
    type: USE_POINTS,
    payload: item,
  });
};

/**
 * Add an item to the cart.
 * We parse the base price and any add-on prices here
 * so the reducer always receives numeric values.
 */
export const AddToCart = (item) => (dispatch) => {
  // Clone the item so we don't mutate original
  const newItem = { ...item };

  // 1. Parse base price if it's a string
  if (typeof newItem.price === 'string') {
    const parsedPrice = parseFloat(newItem.price);
    newItem.price = isNaN(parsedPrice) ? 0 : parsedPrice;
  }

  // 2. If the item has add-ons, parse their prices too
  if (Array.isArray(newItem.selectedAddOns)) {
    newItem.selectedAddOns = newItem.selectedAddOns.map((addOn) => {
      const parsedAddOn = { ...addOn };
      if (typeof parsedAddOn.price === 'string') {
        const parsedAddOnPrice = parseFloat(parsedAddOn.price);
        parsedAddOn.price = isNaN(parsedAddOnPrice) ? 0 : parsedAddOnPrice;
      }
      return parsedAddOn;
    });
  }

  // Now dispatch with numeric price(s)
  dispatch({
    type: ADD_TO_CART,
    payload: newItem,
  });
};

// Decrease quantity
export const Decrease_Qnty = (id) => (dispatch) => {
  dispatch({
    type: DECREASE_QNTY,
    payload: id,
  });
};

// Remove an item completely from the cart
export const RemoveItem = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: id,
  });
};

// Empty the entire cart
export const EmptyCart = () => (dispatch) => {
  dispatch({
    type: EMPTY_CART,
  });
};

// Gather the IDs of items in the cart
export const getIds = () => (dispatch) => {
  dispatch({
    type: GET_CART_IDS,
  });
};

// Recompute total
export const getTotal = () => (dispatch) => {
  dispatch({
    type: GET_TOTAL,
  });
};
