import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    DECREASE_QNTY,
    EMPTY_CART,
    GEL_AL,
    USE_POINTS,
    GET_CART_IDS,
    GET_TOTAL,
    REMOVE_ADDON_FROM_CART,
  } from '../actions/cart';

  function saveToLocalStorage(cart) {
    try {
      const serializedCart = JSON.stringify(cart);
      window.localStorage.setItem('cart', serializedCart);
    } catch (e) {
      console.log(e);
    }
  }
  
  function loadFromLocalStorage() {
    try {
      const serializedCart = window.localStorage.getItem('cart');
      if (serializedCart === null) return undefined;
      const parsed = JSON.parse(serializedCart);
  
      // Ensure total is a number (if previously saved as string)
      if (parsed && typeof parsed.total === 'string') {
        parsed.total = parseFloat(parsed.total) || 0;
      }
  
      return parsed;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }
  
  const initialState =
    loadFromLocalStorage() === undefined
      ? {
          cart: [],
          cartcount: 0,
          total: 0,
          gel_al: false,
          use_points: false,
          cart_ids: [],
        }
      : loadFromLocalStorage();
  function CartReducer(state = initialState, action) {
    switch (action.type) {
      case GEL_AL:
        state.gel_al = action.payload;
        break;
  
      case USE_POINTS:
        state.use_points = action.payload;
        break;
  
      case REMOVE_ADDON_FROM_CART: {
        const { cartItemId, addOnName } = action.payload;
        // Find matching cart item
        state.cart.forEach((element) => {
          if (element.id === cartItemId) {
            // If the item has selectedAddOns, remove the targeted add-on:
            if (element.selectedAddOns && element.selectedAddOns.length > 0) {
              const indexToRemove = element.selectedAddOns.findIndex(
                (addOn) => addOn.name === addOnName
              );
              if (indexToRemove !== -1) {
                const removedAddOn = element.selectedAddOns[indexToRemove];
  
                // Parse the add-on price just in case
                const addOnPrice = parseFloat(removedAddOn.price) || 0;
                // Subtract the add-on price * item quantity
                state.total -= addOnPrice * element.quantity;
  
                // Remove that add-on from the array
                element.selectedAddOns.splice(indexToRemove, 1);
              }
            }
          }
        });
        break;
      }
  
      case ADD_TO_CART: {
        const { id, price } = action.payload;
  
        // Ensure numeric
        const itemPrice = parseFloat(price) || 0;
  
        let found = false;
        state.cart.forEach((element) => {
          if (element.id === id) {
            // If item already in cart, increase quantity
            element.quantity += 1;
            state.total += itemPrice; // partial update
            state.cartcount++;
            found = true;
          }
        });
  
        // Not found => new item
        if (!found) {
          const newItem = {
            ...action.payload,
            price: itemPrice, // store numeric
            quantity: 1,
          };
          state.cart.push(newItem);
          state.total += itemPrice;
          state.cartcount++;
        }
        break;
      }
  
      case DECREASE_QNTY: {
        const id = action.payload;
        state.cart.forEach((element) => {
          if (element.id === id) {
            const index = state.cart.indexOf(element);
  
            // Parse numeric price
            const elementPrice = parseFloat(element.price) || 0;
  
            element.quantity -= 1;
            state.total -= elementPrice;
            state.cartcount--;
  
            // If quantity hits zero, remove the item
            if (element.quantity === 0) {
              state.cart.splice(index, 1);
            }
          }
        });
        break;
      }
  
      case REMOVE_FROM_CART: {
        const id = action.payload;
        state.cart.forEach((element) => {
          if (element.id === id) {
            const index = state.cart.indexOf(element);
            const elementPrice = parseFloat(element.price) || 0;
  
            // Subtract total for that item
            state.total -= elementPrice * element.quantity;
            state.cartcount -= element.quantity;
  
            element.quantity = 0;
            if (element.quantity === 0) {
              state.cart.splice(index, 1);
            }
          }
        });
        break;
      }
  
      case EMPTY_CART:
        state.cart = [];
        state.cartcount = 0;
        state.total = 0;
        break;
  
      case GET_CART_IDS:
        // Collect all IDs in cart_ids
        state.cart_ids = []; // reset or you'll keep pushing duplicates
        state.cart.forEach((element) => {
          state.cart_ids.push(element.id);
        });
        break;
  
      case GET_TOTAL: {
        // Recalculate total from scratch
        let sum = 0;
        state.cart.forEach((element) => {
          const basePrice = parseFloat(element.price) || 0;
          let linePrice = basePrice * element.quantity;          
          // If the item has add-ons, sum them
          if (element.selectedAddOns && element.selectedAddOns.length > 0) {
            const addOnsTotal = element.selectedAddOns.reduce((acc, addOn) => {
              return acc + (parseFloat(addOn.price) || 0);
            }, 0);

            // multiply add-ons by quantity
            linePrice += addOnsTotal * element.quantity;
          }
  
          sum += linePrice;
        });
        state.total = sum;
        break;
      }
  
      default:
        break;
    }
  
    // Save updated state to local storage
    saveToLocalStorage(state);
  
    // Return updated state
    return {
      ...state,
    };
  }
  
  export default CartReducer;
  