import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import logo from "../assets/logo.png"
import "./navbar.css"
import Profilemenu from './profilemenu'; 
import { useEffect } from 'react';
export const NavBar = () => {

  const [flag,setFlag] = useState(false);
  useEffect(() => {
      if(localStorage.getItem("access-token") === null){
        setFlag(false);

      }
      else{
        setFlag(true);
      }
  },[]);
  
  return (
    <>
     <Navbar expand='sm'>
      <Container fluid>
        <Navbar.Brand  href="/"><img className='logo' src={logo}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav  className="me-auto">
            <ul className='zebby'>
              <li>
                <Nav.Link className="nav-link" href="/">ANASAYFA</Nav.Link>
              </li>
              <li>
              <Nav.Link href="/menu">MENU</Nav.Link>
              </li>
              
              {/* <li>
              <Nav.Link href="/signup">HAKKIMIZDA</Nav.Link>
              </li> */}
              <li>
              <Nav.Link href="/contact">İLETİŞİM</Nav.Link>
              </li>
            </ul>
            </Nav>
            <Nav>
              {
                  flag ? 
                  <>
                    <Profilemenu/>
                  </> 
                  :
                  <>
                      <ButtonToolbar aria-label="Toolbar with Button groups">
                        {/* <ButtonGroup className="me-3" aria-label="First group">
                          <Button className="signup" variant="outline-dark" href='/signup'>Kayıt ol</Button>{' '}
                        </ButtonGroup> */}
                        <ButtonGroup className="me-2 mb-3"aria-label="First group">
                          <Button className='login' href="/login">Giriş</Button>{' '}
                        </ButtonGroup>
                      </ButtonToolbar>
                  </>
              }

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}
