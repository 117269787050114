import React from 'react';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import "./contact.css"
import { useState } from 'react';
import { domain } from '../../domain';
const Contact = () => {

  const [email,setEmail] = useState("");
  const [name,setName] = useState("");
  const [message,setMessage] = useState("");
  
  const handlesubmission = () =>{
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("message", message);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${domain}/api/contact/`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  return (
    <>
    <NavBar/>
      <div className="bg-info contact4 p-5 overflow-hiddedn position-relative">
        <div className="row no-gutters">
          <div className="container">
            <div className="col-lg-6 contact-box mb-4 mb-md-0">
              <div className="">
                <form className="mt-3">
                  <div className="row">
                    <div className='form-wrapper'>
                        <h2>Bize Ulaşın</h2>
                        <form  noValidate >
                          <div className='email'>
                              <label htmlFor="email">E-posta:</label>
                              <input type='email'  value={email} name='email' onChange={(e)=> setEmail(e.target.value)}/>
                          </div>
                          <div className='fullName'>
                              <label htmlFor="fullName">Adınız</label>
                              <input type='text'  value={name} name='fullName' onChange={(e)=> setName(e.target.value)}/>
                          </div>
                          <div className='fullName'>
                              <label htmlFor="fullName">Mesaj</label>
                              <textarea rows={3} value={message} name='fullName' onChange={(e)=> setMessage(e.target.value)}/>
                          </div>
                          <div className="px-3 mt-4 border-0 mb-4">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="d-flex align-items-center c-detail">
                                  <div className="pe-3 align-self-center">
                                      <i className="fas fa-phone fa-3x"></i>
                                  </div>
                                  <div className="pt-3">
                                    <p className="">+90 552 494 4813</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="d-flex align-items-center c-detail">
                                  <div className="pe-3 align-self-center">
                                      <i className="fas fa-at fa-3x  "></i>
                                  </div>
                                  <div className="pt-3">
                                    <p className="">
                                    Usa.vibe7@gmail.com</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='submit'>
                              <button onClick={()=> handlesubmission()} className='submit-btn'>Gönder</button>
                          </div>
                      </form>
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 right-image p-3">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3128.005332550436!2d27.18563005095781!3d38.371998085246545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b961ffb5938b59%3A0x4314ba7e4c1e4d23!2sSushi%20Vibe!5e0!3m2!1sen!2str!4v1674475734369!5m2!1sen!2str"
              width="100%" height="100%"  allowfullscreen data-aos="fade-left" loading="lazy" referrerpolicy="no-referrer-when-downgrade"data-aos-duration="3000"></iframe>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default Contact;
