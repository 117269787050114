import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Animation from "./LoadingAnimation.json"
import Lottie from "lottie-react";

const LoadingModal = ({visible}) => {

    const [fade,setFade] = useState(false);
    useEffect(() => {
        setTimeout(()=>{
            setFade(!fade);
        },1000)
    })
    
    return (
        <div className='loadingModal'>
        <Modal className='loadingModal' fullscreen style={{alignItems:'center',display:'flex',width:'auto',justifyContent:'center'}}  backdrop="static" show={visible}>
            <Modal.Body >
                <div style={{alignItems:'center',marginLeft:60,display:'flex',width:'auto',justifyContent:'center',height:'100%',flexDirection:'column'}}>               
                <Lottie  style={{maxWidth:400}}  animationData={Animation} />
                </div>
            </Modal.Body>
        </Modal>
        </div>    
    )
}

export default LoadingModal;