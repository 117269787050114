import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import "./foodcard.css";
import { useDispatch } from 'react-redux';
require.context('../assets', false, /\.(png|jpe?g|svg)$/);

const Foodcard = ({item,onClick}) => {
  const [food,setFood] = useState({});
  const [images,setImages] = useState({});
  useEffect(() => {
    setFood(item);
    if(item.type === "Başlangıçlar"){
      const starters = importAll(require.context("../assets/Starters", false, /\.(png|jpe?g|svg)$/));
      setImages(starters);
    }
    else if(item.type === "Sushi Sets"){
      const sushisets = importAll(require.context("../assets/Sushi Sets", false, /\.(png|jpe?g|svg)$/));
      setImages(sushisets);
    }
    else if(item.type === "Sushi Rolls"){
      const sushirolls = importAll(require.context("../assets/Sushi Rolls", false, /\.(png|jpe?g|svg)$/));
      setImages(sushirolls)
    }
    else if(item.type === "Nigiri"){
      const Nigiri = importAll(require.context("../assets/Nigiri", false, /\.(png|jpe?g|svg)$/));
      setImages(Nigiri);

    }
    else if(item.type === "Snacks"){
      const Snacks = importAll(require.context("../assets/Snacks", false, /\.(png|jpe?g|svg)$/));
      setImages(Snacks);

    }
    else if(item.type === "Korean Fried Chicken"){
      const KFC = importAll(require.context("../assets/Korean Fried Chicken", false, /\.(png|jpe?g|svg)$/));
      setImages(KFC);
    }
    else if(item.type === "Pişmiş Rollar"){
      const YAKI = importAll(require.context("../assets/Yaki Sushi", false, /\.(png|jpe?g|svg)$/));
      setImages(YAKI);
    }
    else if(item.type === "Noodles"){
      const Noodles = importAll(require.context("../assets/Noodles", false, /\.(png|jpe?g|svg)$/));
      setImages(Noodles);
    }
    else if(item.type === "Drinks"){
      const Drinks = importAll(require.context("../assets/Drinks", false, /\.(png|jpe?g|svg)$/));
      setImages(Drinks);
    }
    else if(item.type === "Ramen/Çorbalar"){
      const Drinks = importAll(require.context("../assets/Soup", false, /\.(png|jpe?g|svg)$/));
      setImages(Drinks);
    }
  },[]);


  function importAll(r) {
        let images = {};
         r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
        return images
       }
  return (
      <div className="card" onClick={onClick}>
        <div className="row">
          <img  className="image-food px-2 py-1 d-block" src={food.src=='./' ?  logo :images[`${food.src}.jpg`]} alt=""/>
        </div>
        <div className="row">
          <div className="card-block  pt-1">
            <p className='title'>{food.name ? food.name : "Food"}</p>
          </div>
        </div>
        <div className="pt-1 row p-0 pe-2">
        <p className='price'>{food.price ? food.price  : "100"}{" ₺"}</p>

        </div>
    </div>
  )
}

export default Foodcard;