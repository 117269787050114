import React, { useEffect, useState } from 'react';
import './cart.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  AddToCart,
  EmptyCart,
  RemoveAddOnFromCart,
  Decrease_Qnty,
  getTotal
} from '../statemanagement/actions/cart';
import Collapse from 'react-bootstrap/Collapse';
import { domain } from '../domain';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Lottie from 'lottie-react';
import Animation1 from "./CheckMarkAnimation.json"
import Animation2 from "./ErrorAnimation.json"
import Loading from './Loading';
import { Button } from 'react-bootstrap';
export const Cart = (Checkout) => {
  const { cart, total } = useSelector((state) => state.CartReducer);
  const [message,setMessage] = useState('success')
  const [status,setStatus] = useState('unset');
  const dispatch = useDispatch();
  const [table,setTable] = useState('1')
  const [open,setOpen] = useState('');

  useEffect(() => {
    dispatch(getTotal())
  }, [total]);
  const RenderTotal = () => {
      return parseFloat(total).toFixed(2);
  };
  const getUserLocation = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
  
      if (permissionStatus.state === "denied") {
        throw new Error("Location permission denied. Please enable location access in your browser settings.");
      }
  
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    } catch (error) {
      throw error;
    }
  };
  

  const haversineDistance = (coords1, coords2) => {
    const toRad = (angle) => (angle * Math.PI) / 180;
    const R = 6371; // Radius of Earth in km
    const dLat = toRad(coords2.lat - coords1.lat);
    const dLon = toRad(coords2.lng - coords1.lng);
    const lat1 = toRad(coords1.lat);
    const lat2 = toRad(coords2.lat);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const targetLocation = { lat: 38.371986, lng: 27.187855 }; // SushiVibeLocation
  const checkProximity = async () => {
    setStatus("loading");
  
    try {
      const userLocation = await getUserLocation();
      const distance = haversineDistance(userLocation, targetLocation);  
      if (distance <= 1) {
        return true;
      } else {
        setMessage("Sipariş vermek için restorana 1 km içinde olmalısınız.");
        setStatus("error");
        return false;
      }
    } catch (error) {
      console.error("Error getting location:", error);
  
      if (error.message.includes("denied")) {
        setMessage("Konum izni verilmedi. Lütfen ayarlardan konum erişimine izin verin.");
      } else {
        setMessage("Hata oluştu, konum bilgisi alınamadı.");
      }
  
      setStatus("error");
      return false;
    }
  };
  
  function makeOrder(){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const order_items = cart.map((item) => ({
      name: item.name,
      quantity: item.quantity,
      type: item.type,
      price: item.price,
      additional_products: item.selectedAddOns.map((addon) => ({
        name: addon.name,
        price: addon.price
      }))
    }));

    const raw = JSON.stringify({
      details: `Order from table ${table}`,
      is_delivered: false,
      tableId: parseInt(table),
      total: parseFloat(total),
      order_items: order_items
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch(`${domain}/api/order/`, requestOptions)
    .then(response => response.text())
    .then(result => {
        const res = JSON.parse(result);
        if(res.id){
          setStatus('success');
          localStorage.removeItem('cart');
          dispatch(EmptyCart())
          setMessage("Siparişiniz başarıyla oluşturuldu, Lütfen garsonlarımızın masanıza gelmesini bekleyin.");
        }
        else{
          setStatus('error');
          setMessage("Sipariş oluşturulamadı. Lütfen tekrar deneyin.");
        }
    })
    .catch(error =>{
      setStatus('error');
      setMessage("Sipariş oluşturulamadı. Lütfen tekrar deneyin.");
      console.log('error',error);
    });
  }


  return (
    status === 'unset' ?     
    <div className="sticky-cart">
      {cart.length === 0 ? (
        <table className="table cart-table mb-0">
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="cart-empty">
                  <i className="fa fa-shopping-cart"></i>
                  <p>Sepetiniz boş</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="table-responsive-xl">
          <table className="card table cart-table mb-0">
            <div className="card-header bg-dark p-2">
              <div className="card-header-flex">
                <h5 className="text-white m-0">
                  {' '}
                  Sepetim
                </h5>
                {cart.length > 0 && (
                  <button
                    className="btn btn-danger mt-0 btn-sm mb-0"
                    onClick={() => dispatch(EmptyCart())}
                  >
                    <i className="fa fa-trash-alt mr-2"></i>
                    <span>Sepeti Boşalt</span>
                  </button>
                )}
              </div>
            </div>
            <tbody>
              {cart.map((data) => {
                const { id, name, price, quantity, selectedAddOns } = data;
                const basePrice = parseFloat(price) || 0;
                const itemAddOnPrice = selectedAddOns
                  ? selectedAddOns.reduce((acc, addOn) => {
                      return acc + parseFloat(addOn.price || 0);
                    }, 0)
                  : 0;
                const itemTotalPrice = (basePrice + itemAddOnPrice) * quantity;
                return (
                  <>
                    <tr key={id} >
                      <td colSpan="3" style={selectedAddOns.length > 0 ? {borderTopWidth:'0px',borderBottomWidth:"0px"} : {}}>
                        <div className="product-name">
                          <p>{name}</p>
                        </div>
                      </td>
                      <td colSpan="3" style={selectedAddOns.length > 0 ? {borderTopWidth:'0px',borderBottomWidth:"0px"}:{}}>
                        <div className="prdct-qty-container">
                          <button
                            className="prdct-qty-btn"
                            type="button"
                            onClick={() => dispatch(Decrease_Qnty(id))}
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                          <input
                            type="text"
                            name="qty"
                            className="qty-input-box"
                            value={quantity}
                            disabled
                          />
                          <button
                            className="prdct-qty-btn"
                            type="button"
                            onClick={() => {dispatch(AddToCart(data))}}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>
                      </td>
                      <td colSpan={3}  style={selectedAddOns.length > 0 ?  {borderTopWidth:'0px',borderBottomWidth:"0px"} :{}}>
                        {itemTotalPrice.toFixed(2)}₺
                      </td>
                      <td  style={selectedAddOns.length > 0 ?  {borderTopWidth:'0px',borderBottomWidth:"0px"} :{}}>
                        <Button aria-controls="example-collapse-text" aria-expanded={open === id}  className='btn btn-secondary' onClick={()=>{ open === id ? setOpen('') : setOpen(id) }} 
                                > {open === id ? "▴" : "▾"} </Button>
                      </td>
                    </tr>
                  {selectedAddOns && selectedAddOns.length > 0 && (
                    <Collapse in={open === id} className='w-'>
                        <tr>
                        <td colSpan={12} style={{borderBottomWidth:"0px",borderTopWidth:'0px'}} className='pt-0'>
                          <ul className="list-unstyled ms-0 mb-0 pt-0">
                          {selectedAddOns.map((addOn, index) => (
                              <li key={index}>
                              <button className="btn btn-sm btn-danger me-2 my-1"
                                onClick={() => dispatch(RemoveAddOnFromCart(id, addOn.name))}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                              {addOn.name} ({addOn.price}₺)
                              </li>
                          ))}
                          </ul>
                        </td>
                        </tr>
                    </Collapse>
                  )}
                  </>
                );
              })}
              <tr>
                <th colSpan="4" className="text-left w-100">
                  Toplam<span className="ml-1 mr-2">:</span>
                  <span className="text-danger">
                    {' '}
                    {RenderTotal()} ₺
                  </span>
                  <div>
                  <DropdownButton id="dropdown-basic-button"  title={`Seçilen Masa: ${table}`} variant={'danger'}>
                    <Dropdown.Item eventKey="T-1" onClick={() => setTable("1")}>Masa 1</Dropdown.Item>
                    <Dropdown.Item eventKey="T-2" onClick={() => setTable("2")}>Masa 2</Dropdown.Item>
                    <Dropdown.Item eventKey="T-3" onClick={() => setTable("3")}>Masa 3</Dropdown.Item>
                  </DropdownButton>
                  </div>
                </th>
                <th className="a7a" colSpan="4">
                  <button
                    className="mt-0 check-btn"
                    onClick={async () => {
                      const flag = await checkProximity();
                      if(flag){
                        makeOrder()
                      }}}
                  >
                    <span>{Checkout ? 'Sipariş Ver' : 'Kasaya Git'}</span>
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
    : 
    <div className="sticky-cart">
        <table className="table cart-table mb-0">
          <tbody>
            <tr>
              <td colSpan="12">
                <div style={{alignItems:'center',marginRight:'auto',marginLeft:'auto',display:'flex',width:'auto',justifyContent:'center',height:'100%',flexDirection:'column'}}>  
                {status === 'loading' ? <Loading/> : 
                <>
                  <Lottie  style={{maxWidth:400}}  animationData={ status === "success" ?  Animation1 : Animation2} />
                  <h6 style={{textAlign:'center'}}>{message}</h6>
                </>}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
  );
};
